import React, { Component } from 'react'

class Contact extends Component {
  constructor() {
    super()
    this.handleSubmit = this.handleSubmit.bind(this)

    this.state = {
      error: '',
    }
  }

  handleSubmit(event) {
    event.preventDefault()
    const data = new FormData(event.target)

    fetch(
      'http://backend.zamanq.com/wp-json/contact-form-7/v1/contact-forms/63/feedback',
      {
        method: 'POST',
        body: data,
      }
    )
      .then(response => response.json())
      .then(data => {
        if (data.status === 'mail_sent') {
          // this.setState({
          //   success: "Thanks! the form has been sent successfully.",
          // })
          document.location = '/success'
        }
      })
      .catch(error => this.setState({ error }))
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="row">
          <div className="input-field">
            <input
              id="fname"
              type="text"
              name="fname"
              className="validate"
              required
            />
            <label for="fname">Name</label>
          </div>
        </div>
        <div className="row">
          <div className="input-field">
            <input
              id="email"
              type="email"
              name="email"
              className="validate"
              required
            />
            <label for="email">Email</label>
          </div>
        </div>
        <div className="row">
          <div className="input-field">
            <input
              id="subject"
              type="text"
              name="subject"
              className="validate"
            />
            <label for="subject">Topic</label>
          </div>
        </div>
        <div className="row">
          <div className="input-field">
            <textarea
              id="message"
              name="message"
              className="materialize-textarea"
            />
            <label for="message">Details</label>
          </div>
        </div>
        <div className="row">
          <div className="input-field">
            <button className="btn btn-large purple" id="submit">
              Send
            </button>
          </div>
          <p>{this.state.error}</p>
        </div>
      </form>
    )
  }
}

export default Contact
