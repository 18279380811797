import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import ContactForm from '../components/contact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PageHeader from '../utils/PageHeader'

const ContactPage = () => {
  const data = useStaticQuery(graphql`
    query {
      wordpressPage(wordpress_id: { eq: 126 }) {
        title
        content
        featured_media {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        yoast_meta {
          yoast_wpseo_title
          yoast_wpseo_metadesc
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title="Contact"
        keywords={['contact']}
        description={data.wordpressPage.yoast_meta.yoast_wpseo_metadesc}
      />
      <PageHeader
        img={data.wordpressPage.featured_media.localFile.childImageSharp.fluid}
      >
        <div className="container">
          <h1 className="portfolio-heading">{data.wordpressPage.title}</h1>
        </div>
      </PageHeader>
      <section style={{ marginTop: '50px' }}>
        <div className="container">
          <div className="row">
            <div className="col s12 m5 l5">
              <h4 style={{ lineHeight: '1.3em' }}>
                Have a project in mind? Let's discuss it in detail.
              </h4>
              <a style={{ color: 'purple', fontSize: '25px' }} href="mailto:itszamanq@gmail.com">Send me an Email</a>
            </div>
            <div className="col s12 m6 l6">
              {/* <ContactForm /> */}

            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ContactPage
